import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The SemanticVersion scalar type is a software version represented as UTF-8 character sequences.
Accepted format is: `}<inlineCode parentName="p">{`MAJOR.MINOR.PATCH`}</inlineCode>{`. i.e: `}<inlineCode parentName="p">{`0.0.1`}</inlineCode>{`, `}<inlineCode parentName="p">{`1.0.0`}</inlineCode>{`, `}<inlineCode parentName="p">{`2.4.3`}</inlineCode>{`.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">scalar</GQLKeyword> SemanticVersion</GQLLine>
    </GQLCodeSnippet>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      